@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap");

body {
  font-family: "Source Code Pro", monospace;
  background: #ffffff;
}

* {
  font-weight: 400;
}

.App {
  padding: 40px;
}

.header {
  display: grid;
  grid: auto / auto 1fr;
  column-gap: 10px;
  padding-bottom: 20px;
  border-bottom: thin dashed black;
}

.message {
  padding-bottom: 20px;
}

.footer {
  display: grid;
  grid: auto / auto 1fr;
  column-gap: 10px;
  padding: 20px 0;
  border-top: thin dashed black;
}

.metadata {
  opacity: 0.4;
  display: grid;
  grid: auto / auto 1fr;
  column-gap: 20px;
  row-gap: 4px;
}

.metadata span:last-child {
  grid-column: span 2;
}

.bar-header {
  padding-bottom: 20px;
  border-bottom: thin dashed black;
  text-align: center;
}

.bar-header svg {
  height: 100px;
  width: auto;
}

.bar-order-time {
  margin: 20px 0;
  text-align: right;
}

.bar-items {
  list-style: none;
  margin: 0;
  padding: 0;
}

.bar-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.bar-item > span:first-child {
  display: flex;
  column-gap: 12px;
}

.bar-total {
  margin: 120px 0 60px;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  border-top: thin dashed black;
}

.bar-total > span:first-child {
  font-weight: 800;
}